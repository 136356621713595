.widget-header{
    background: $blue;
    color: $off-white;
    padding: $m-size;
    display: flex;
    justify-content: space-between;
    &--title{
        margin: 0;
    }
}
.widget{
    background-color: $light-blue;
    &--message{
        border-bottom: 1px solid lighten($color: $light-blue, $amount: 10%);
        color: $off-white;
        padding: $l-size;
        margin: 0;
        text-align: center;
    }
}