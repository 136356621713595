.header{
    background-color: $off-black;
    color: white;
    margin-bottom: $xl-size;
    padding: $m-size 0;
    //Block Element Modifier
    &--title{
        font-size: $l-size;
        margin: 0;
    }
    &--subTitle{
        font-size: $m-size;
        color: $off-white;
        font-weight: 500;
        margin: 0;
    }
}