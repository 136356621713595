html{
    font-size: 10px;
}

body{
    font-family: Arial, Helvetica, sans-serif, "华文细黑", "微软雅黑", "宋体";
    font-size: $m-size;
    background-color: $dark-blue;
}

button{
    cursor: pointer;
}
button:disabled{
    cursor: default;
}
