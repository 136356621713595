.option{
    display: flex;
    justify-content: space-between;
    padding: $l-size $m-size;
    border-bottom: 1px solid lighten($color: $light-blue, $amount: 10%);
    &--text{
        color: white;
        font-weight: 500;
        font-size: 2rem;
        margin: 0;
        word-break: break-all;
    }
}