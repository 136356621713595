.app-option-error{
    color: $off-white;
    padding: 0 $m-size;
    font-style: italic;
    margin: $m-size 0 0 0;
}
.add-option{
    display: flex;
    padding: $m-size;
    &--input{
        flex-grow: 0.9;
        background: $dark-blue;
        border: none;
        border-bottom: .3rem solid darken($color: $dark-blue, $amount: 10%);
        margin-right: $s-size;
        color: $off-white;
        padding: $s-size;
    }
}