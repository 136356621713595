//颜色
$off-black: #20212b;
$off-white: #a4add5;
$dark-blue: #323745;
$blue: #3c4251;
$light-blue: #474b5f;
$purple: #8457c5;
//字体大小
$s-size: 0.8rem;
$m-size: 1.4rem;
$l-size: 2.2rem;
$xl-size: 3.8rem;
