.reactModalPortal .ReactModal__Overlay{
    opacity: 0;
    transition: opacity 200ms ease-in-out; //过度效果
    display: flex;
    justify-content: center;
    align-items: center;
    
}
.ReactModalPortal.ReactModal__Overlay--after-open{
    opacity: 1;
}
.ReactModalPortal.ReactModal__Overlay--before-close{
    opacity: 0;
}
.modal{
    background-color: $dark-blue;
    color: white;
    outline: none;
    max-width: 30rem;
    padding: $l-size;
    text-align: center;
    &__title{
        margin: 0 0 $m-size 0;
    }
    &__body{
        margin: 0 0 $l-size 0;
        font-size: 2rem;
        font-weight: 300;
        word-break: break-all;
    }
}