.big-button{
    background-color: $purple;
    border: none;
    border-bottom: .6rem solid darken($color: $purple, $amount: 10%);
    color: white;
    font-weight: 500;
    font-size: $l-size;
    margin-bottom: $xl-size;
    padding: 2.4rem;
    width: 100%;
    &:disabled{
        opacity: 0.5;
    }
}
//bem block element m error primary
.button{
    background: $purple;
    border: none;
    border-bottom: 0.3rem solid darken($color: $purple, $amount: 10%);
    color: white;
    font-weight: 500;
    padding: $s-size;
}
//利用修饰符可以改变button的样式
.button--link{
    background: none;
    border: none;
    color: $off-white;
    padding: 0;
}